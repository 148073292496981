import Headroom from "headroom.js";

// export const header = ($el) => {
//     console.log("# Header initialized");

//     const scrolledClass = "is-scrolled";

//     window.addEventListener("scroll", () => {
//         if (window.scrollY > 100) {
//             $el.classList.add(scrolledClass);
//         } else {
//             $el.classList.remove(scrolledClass);
//         }
//     });

// };

export const header = ($el) => {
  return {
    scrolledClass: "is-scrolled",
    navOpen: false,
    toggleNav() {
      this.navOpen = !this.navOpen;
      $el.classList.toggle("is--open");
    },
    headerClasses() {
      const options = {
        // vertical offset in px before element is first unpinned
        // offset: 0,
        // or you can specify offset individually for up/down scroll
        offset: {
          up: 200,
          down: 100,
        },
        // scroll tolerance in px before state changes
        tolerance: 0,
        // or you can specify tolerance individually for up/down scroll
        // tolerance: {
        //   up: 200,
        //   down: 100,
        // },
        // css classes to apply
        classes: {
          // when element is initialised
          // initial: "--initial",
          // when scrolling up
          pinned: "is--pinned",
          // when scrolling down
          unpinned: "is--unpinned",
          // when above offset
          top: "is--top",
          // when below offset
          notTop: "is--not-top",
          // when at bottom of scroll area
          bottom: "is--bottom",
          // when not at bottom of scroll area
          notBottom: "is--not-bottom",
          // when frozen method has been called
          frozen: "headroom--frozen",
          // multiple classes are also supported with a space-separated list
          // pinned: "headroom--pinned foo bar",
        },
      };

      const headroom = new Headroom($el, options);
      headroom.init();
    },
    init() {
      console.log("# Header initialized");

      this.headerClasses();
    },
  };
};
