import Splitting from "splitting";
import anime from "animejs";

import { gsap } from "@js/libs/gsap";
import { ScrollTrigger } from "@js/libs/gsap/ScrollTrigger";
import { MotionPathPlugin } from "@js/libs/gsap/MotionPathPlugin";
import { DrawSVGPlugin } from "@js/libs/gsap/DrawSVGPlugin";
import { MorphSVGPlugin } from "@js/libs/gsap/MorphSVGPlugin";
import { SplitText } from "@js/libs/gsap/SplitText";
import { ScrollToPlugin } from "@js/libs/gsap/ScrollToPlugin";

gsap.registerPlugin(
  DrawSVGPlugin,
  MorphSVGPlugin,
  SplitText,
  ScrollTrigger,
  ScrollToPlugin,
  MotionPathPlugin
);
gsap.config({
  nullTargetWarn: false,
});

const scrollAnimations = () => {
  let elements = document.querySelectorAll("[data-enter]");
  const scrollObserver = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) return;

        const element = entry.target;

        switch (element.dataset.enter) {
          case "case":
            const wrapper = element.querySelector(".c-case-cover__wrapper"),
              background = element.querySelector(".c-case-cover__background"),
              logo = element.querySelector(".c-case-cover__logo"),
              title = element.querySelector(".c-case-cover__title"),
              button = element.querySelector("button");

            const tl = gsap.timeline();

            tl.fromTo(
              wrapper,
              {
                opacity: 0,
                scaleY: 0,
              },
              {
                opacity: 1,
                scaleY: 1,
                duration: 0,
                ease: "expo.out",
              }
            )
              .fromTo(
                background,
                {
                  scaleY: 0,
                },
                {
                  scaleY: 1,
                  duration: 1,
                  ease: "power4.out",
                },
                0
              )
              .fromTo(
                logo,
                {
                  opacity: 0,
                  translateY: "-2rem",
                },
                {
                  opacity: 1,
                  translateY: "0",
                  duration: 1.5,
                  ease: "expo.out",
                },
                "-=.5"
              )
              .fromTo(
                button,
                {
                  opacity: 0,
                  translateY: "2rem",
                },
                {
                  opacity: 1,
                  translateY: "0",
                  duration: 1.5,
                  ease: "expo.out",
                },
                "-=1"
              );

            // listen to scroll and make the title and logo translateY negatively on different speeds
            ScrollTrigger.create({
              trigger: element,
              start: "top top",
              end: "bottom top",
              onUpdate: (self) => {
                gsap.set(title, {
                  translateY: -self.progress * 100,
                });
                gsap.set(logo, {
                  translateY: -self.progress * 200,
                  opacity: 1 - self.progress,
                });
                gsap.set(background, {
                  translateY: -self.progress * 375,
                });
                gsap.set(button, {
                  translateY: -self.progress * 605,
                });
              },
            });

            break;

          case "404":
            const mouthHappy = element.querySelector("#mouthHappy"),
              mouthSad = element.querySelector("#mouthSad");

            gsap.to(mouthHappy, {
              morphSVG: mouthSad,
              duration: 0.4,
              ease: "Power0.easeNone",
              delay: 0.6,
            });

            break;

          default:
            break;
        }

        entry.target.classList.add("is-inview");
        observer.unobserve(entry.target);
      });
    },
    {
      rootMargin: "0px 0px -15% 0px",
      // rootMargin: "0px 0px -20% 0px",
    }
  );

  elements.forEach((el) => {
    scrollObserver.observe(el);
  });

  // let tl = gsap.timeline({
  //   scrollTrigger: {
  //     trigger: ".dot",
  //     start: "bottom 100%",
  //     scrub: true,
  //     pin: false,
  //   },
  // });

  // tl.to(".dot .dotdot", {
  //   scale: "5",
  // }).to(
  //   ".dot .js-scroll-to",
  //   {
  //     opacity: 0,
  //     y: -100,
  //   },
  //   "<"
  // );
};

const textEffects = () => {
  let target = document.querySelectorAll(".splitting");

  if (!target.length) return;

  target.forEach((el) => {
    let content;
    let test = el.querySelectorAll("* > *:not(br):not(span)");

    if (test.length > 0) {
      content = el.querySelectorAll("* > *:not(br):not(span)");
    }

    new SplitText(el, {
      tag: "span",
      type: "lines",
      linesClass: "line",
      wordsClass: "word",
      reduceWhiteSpace: false,
    });

    el.querySelectorAll(".line").forEach((word, index) => {
      word.style = `--line-index: ${index}`;
    });
    el.querySelectorAll(".word").forEach((word, index) => {
      word.style = `--word-index: ${index}`;
    });
  });
};

const scrollToElement = (hash) => {
  const targets = document.querySelectorAll(".js-scroll-to");

  // if (hash) {
  //   const destination = document.getElementById(hash.replace("#", ""));
  //   const header = document.querySelector(".js-header");
  //   const headerHeight = header ? header.offsetHeight : 0;

  //   if (!destination) return;

  //   gsap.to(window.document.documentElement, {
  //     duration: 1,
  //     ease: "power2.inOut",
  //     scrollTo: destination.offsetTop - headerHeight,
  //   });
  // }

  if (!targets.length) return;
  targets.forEach((el) => {
    el.addEventListener("click", (e) => {
      e.preventDefault();
      const id = el.getAttribute("href") || el.getAttribute("data-href");
      const destination = document.getElementById(id.replace("#", ""));
      const header = document.querySelector(".js-header");
      const headerHeight = header ? header.offsetHeight : 0;

      if (!destination) return;

      gsap.to(window.document.documentElement, {
        duration: 1,
        ease: "power2.inOut",
        scrollTo: destination.offsetTop - headerHeight,
      });
    });
  });
};

const titleEffects = () => {
  const shapesContainer = document.querySelector(".js-shapes");
  const effectTitles = document.querySelectorAll(".js-title-effect");

  if (!effectTitles) return;

  console.log("🚀 Title effects");

  effectTitles.forEach((el) => {
    // Stars
    if (el.classList.contains("js-effect-stars")) {
      const shape = shapesContainer.querySelector(".shape-star");
      // const element = el.querySelector(".js-marked-word");

      const star1 = shape.cloneNode(true);
      const star2 = shape.cloneNode(true);
      const star3 = shape.cloneNode(true);
      const star4 = shape.cloneNode(true);

      // el.appendChild(shape.cloneNode(true));

      el.appendChild(star1);
      el.appendChild(star2);
      el.appendChild(star3);
      el.appendChild(star4);

      star1.classList.add("star-1", "absolute", "z-below");
      star2.classList.add("star-2", "absolute", "z-below");
      star3.classList.add("star-3", "absolute", "z-below");
      star4.classList.add("star-4", "absolute", "z-below");

      /* Draw the shape with GSAP DrawSVGPlugin */

      gsap.set(star1, {
        autoAlpha: 0,
        left: "40%",
        top: "-20%",
        transformOrigin: "center center",
      });
      gsap.set(star2, {
        autoAlpha: 0,
        left: "100%",
        top: "20%",
        transformOrigin: "center center",
      });
      gsap.set(star3, {
        autoAlpha: 0,
        left: "0%",
        top: "60%",
        transformOrigin: "center center",
      });
      gsap.set(star4, {
        autoAlpha: 0,
        left: "75%",
        top: "90%",
        transformOrigin: "center center",
      });

      let stars = [star1, star2, star3, star4];

      // const tl = gsap.timeline();

      const random = (min, max) => {
        return Math.random() * (max - min) + min;
      };

      var appearMin = 0.3;
      var appearMax = 0.8;

      var delayMin = 1;
      var delayMax = 1.5;

      var durationMin = 0.3;
      var durationMax = 1;

      stars.forEach((star) => {
        const sign = Math.random() < 0.5 ? "-=" : "+=";
        // tl.to(star, {
        //   scale: random(0.55, 1),
        //   rotation: sign + random(30, 90),
        //   autoAlpha: 1,
        // });

        const tl = gsap.timeline({ repeat: -1, yoyo: true });

        var alpha = random(0.9, 1);
        var scale = random(0.55, 1);

        var appear = "+=" + random(appearMin, appearMax);
        var delay = "+=" + random(delayMin, delayMax);
        var duration1 = random(durationMin, durationMax);
        var duration2 = random(durationMin, durationMax);

        tl.to(
          star,
          duration1,
          { autoAlpha: alpha, scale: scale, rotation: sign + random(30, 90) },
          delay
        ).to(
          star,
          duration2,
          {
            rotation: sign + random(30, 90),
            autoAlpha: 0,
            scale: 0,
          },
          appear
        );

        // const repeat = gsap.timeline();
        // const timeout = setTimeout(() => {
        //   repeat.fromTo(
        //     star,
        //     {
        //       scale: gsap.getProperty(star, "scale"),
        //       opacity: 1,
        //     },
        //     {
        //       scale: gsap.getProperty(star, "scale") * 0.79,
        //       duration: 1,
        //       repeat: -1,
        //       // repeatDelay: 1.5,
        //       yoyo: true,
        //     }
        //   );
        // }, random(1000, 2000));
      });

      // // Initial state
      // gsap.fromTo(
      //   star1,
      //   {
      //     scale: 0,
      //   },
      //   {
      //     scale: 1,
      //     duration: 1.2,
      //     delay: 1,
      //     rotate: "50deg",
      //     ease: "expo.out",
      //   }
      // );
      // gsap.fromTo(
      //   star2,
      //   {
      //     scale: 0,
      //   },
      //   {
      //     scale: 0.9,
      //     duration: 1.2,
      //     delay: 1,
      //     rotate: "-45deg",
      //     ease: "expo.out",
      //   }
      // );
      // gsap.fromTo(
      //   star3,
      //   {
      //     scale: 0,
      //   },
      //   {
      //     scale: 0.8,
      //     duration: 1.2,
      //     delay: 1,
      //     rotate: "-50deg",
      //     ease: "expo.out",
      //   }
      // );
      // gsap.fromTo(
      //   star4,
      //   {
      //     scale: 0,
      //   },
      //   {
      //     scale: 0.55,
      //     duration: 1.2,
      //     delay: 1,
      //     rotate: "50deg",
      //     ease: "expo.out",
      //   }
      // );
    }

    // Circle
    if (el.classList.contains("js-effect-circle")) {
      const shape = shapesContainer.querySelector(".shape-circle");
      const element = el.querySelector(".js-marked-word");
      if (!element) return;

      element.appendChild(shape.cloneNode(true));

      /* Draw the shape with GSAP DrawSVGPlugin */
      gsap.fromTo(
        element.querySelector("path"),
        {
          drawSVG: "0%",
        },
        {
          drawSVG: "100%",
          duration: 1.2,
          delay: 1,
          ease: "expo.out",
        }
      );
    }
    // Wave Underline
    if (el.classList.contains("js-effect-waves")) {
      const shape = shapesContainer.querySelector(".shape-waves");
      const element = el.querySelector(".js-marked-word");
      if (!element) return;

      element.appendChild(shape.cloneNode(true));

      /* Draw the shape with GSAP DrawSVGPlugin */
      gsap.fromTo(
        element.querySelector("path"),
        {
          drawSVG: "0%",
        },
        {
          drawSVG: "100%",
          duration: 2,
          delay: 1,
          ease: "expo.out",
        }
      );
    }
    // Scratch through
    if (el.classList.contains("js-effect-scratch")) {
      const shape = shapesContainer.querySelector(".shape-scratch");
      const element = el.querySelector(".js-marked-word");
      if (!element) return;

      element.appendChild(shape.cloneNode(true));

      /* Draw the shape with GSAP DrawSVGPlugin */
      gsap.fromTo(
        element.querySelector("path"),
        {
          drawSVG: "0%",
        },
        {
          drawSVG: "100%",
          duration: 2,
          delay: 1,
          ease: "steps.out",
          repeat: -1,
          repeatDelay: 3,
        }
      );
    }
    // Curl effect
    if (el.classList.contains("js-effect-curl")) {
      const shape = shapesContainer.querySelector(".shape-curl");
      const element = el;
      if (!element) return;

      element.appendChild(shape.cloneNode(true));

      /* Draw the shape with GSAP DrawSVGPlugin */
      gsap.fromTo(
        element.querySelector("path"),
        {
          drawSVG: "0%",
        },
        {
          drawSVG: "100%",
          duration: 2,
          delay: 1,
          ease: "steps.out",
        }
      );
    }

    // Marker
    if (el.classList.contains("js-effect-marker")) {
      let words = el.querySelectorAll(".js-marked-word");

      if (!words.length) return;

      words.forEach((word, index) => {
        console.log(index, word.offsetLeft, word.offsetWidth);

        /* Insert element with width of the line in parent line element */
        word.insertAdjacentHTML(
          "afterbegin",
          `<span class="line__width" style="width:100%"></span>`
        );

        gsap.fromTo(
          word.querySelector(".line__width"),
          {
            scaleX: 0,
          },
          {
            scaleX: 1,
            duration: 1,
            delay: 1 + index * 0.2,
            ease: "expo.out",
          }
        );
      });

      // el.querySelectorAll(".line").forEach((line) => {
      //   let linesWidth = 0;
      //   let lineStart = 0;
      //   let lineEnd = 0;

      //   const lineWidth = line.offsetWidth;
      //   let lines = line.querySelectorAll(".js-marked-word");

      //   if (!lines.length) return;

      //   lines.forEach((word, index) => {
      //     console.log(index, word.offsetLeft, word.offsetWidth);

      //     /* get first element in loop */
      //     if (index === 0) {
      //       lineStart = word.offsetLeft;
      //     }
      //     if (index === lines.length - 1) {
      //       lineEnd = word.offsetWidth + word.offsetLeft;
      //       linesWidth = lineEnd - lineStart;
      //     }
      //   });

      //   /* Insert element with width of the line in parent line element */
      //   line.insertAdjacentHTML(
      //     "afterbegin",
      //     `<span class="line__width" style="left: ${lineStart}px; right: ${lineEnd}px; width: ${linesWidth}px"></span>`
      //   );
      // });
    }
  });
};

const methodAnimation = () => {
  const base = document.querySelector(".js-method-animation");

  if (!base) return;

  gsap.set(".stroke", {
    drawSVG: "0",
  });

  function step1(reverse = false) {
    const tl = gsap.timeline({
      ease: "power2.inOut",
    });

    if (reverse) {
      tl.reverse();
    } else {
      tl.to(".stroke-1", {
        drawSVG: "100%",
        duration: 2,
      }).to(
        "#arrow-1",
        {
          motionPath: {
            path: ".stroke-1",
            align: ".stroke-1",
            alignOrigin: [0.51, 0.05],
            autoRotate: -90,
          },
          duration: 2,
        },
        0
      );
    }
  }

  function step2() {
    const tl = gsap.timeline();

    tl.to(".stroke-2", {
      drawSVG: "100%",
      duration: 2,
      ease: "none",
    }).to(
      "#arrow-2",
      {
        motionPath: {
          path: ".stroke-2",
          align: ".stroke-2",
          alignOrigin: [0.5, 0.05],
          autoRotate: -90,
        },
        duration: 2,
        ease: "none",
      },
      0
    );
  }

  function step3() {
    const tl = gsap.timeline();

    tl.to(".stroke-3", {
      drawSVG: "100%",
      duration: 2,
      ease: "none",
    }).to(
      "#arrow-3",
      {
        motionPath: {
          path: ".stroke-3",
          align: ".stroke-3",
          alignOrigin: [0.5, 0.05],
          autoRotate: -90,
        },
        duration: 2,
        ease: "none",
      },
      0
    );
  }

  function step4() {
    const tl = gsap.timeline();

    tl.to(".stroke-4", {
      drawSVG: "100%",
      duration: 2,
      ease: "none",
    }).to(
      "#arrow-4",
      {
        motionPath: {
          path: ".stroke-4",
          align: ".stroke-4",
          alignOrigin: [0.5, 0.05],
          autoRotate: -90,
        },
        duration: 2,
        ease: "none",
      },
      0
    );
  }

  function step5() {
    const tl = gsap.timeline();

    tl.to(".stroke-5", {
      drawSVG: "100%",
      duration: 2,
      ease: "none",
    }).to(
      "#arrow-5",
      {
        motionPath: {
          path: ".stroke-5",
          align: ".stroke-5",
          alignOrigin: [0.5, 0.05],
          autoRotate: -90,
        },
        duration: 2,
        ease: "none",
      },
      0
    );
  }

  const play1 = document.querySelector("#play1");
  const reverse1 = document.querySelector("#reverse1");
  const play2 = document.querySelector("#play2");
  const play3 = document.querySelector("#play3");
  const play4 = document.querySelector("#play4");
  const play5 = document.querySelector("#play5");
  const reset = document.querySelector("#reset");

  reset.addEventListener("click", function () {
    tl.revert();
  });

  play1.addEventListener("click", function () {
    step1();
  });
  reverse1.addEventListener("click", function () {
    step1(true);
  });
  play2.addEventListener("click", function () {
    step2();
  });
  play3.addEventListener("click", function () {
    step3();
  });
  play4.addEventListener("click", function () {
    step4();
  });
  play5.addEventListener("click", function () {
    step5();
  });
};

const initAnimations = () => {
  textEffects();
  titleEffects();
  scrollAnimations();
  scrollToElement();

  if (window.location.hash) {
    const hash = window.location.hash;

    if (hash.length) {
      scrollToElement(hash);
    }
  }
};

const resetTextEffects = () => {
  // titleEffects();
};

export { resetTextEffects, initAnimations };
