import { gsap } from "@js/libs/gsap";
import { MotionPathPlugin } from "@js/libs/gsap/MotionPathPlugin";
import { DrawSVGPlugin } from "@js/libs/gsap/DrawSVGPlugin";

gsap.registerPlugin(DrawSVGPlugin, MotionPathPlugin);
gsap.config({
  nullTargetWarn: false,
});

import { Splide } from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

import "@splidejs/splide/dist/css/splide-core.min.css";

function infiniteSlider() {
  console.log("infiniteSlider");

  const elements = document.querySelectorAll(".js-slider-infinite");

  if (!elements) {
    return;
  }

  const splideConfig = {
    type: "loop",
    drag: false,
    gap: "48px",
    // autoWidth: true,
    fixedWidth: true,
    autoScroll: {
      speed: 0.75,
      pauseOnHover: false,
      pauseOnFocus: false,
    },
    arrows: false,
    pagination: false,
  };

  elements.forEach((el) => {
    let splide = new Splide(el, splideConfig);
    splide.mount({ AutoScroll });
  });
}

function singleCenteredSlider() {
  console.log("Single centered slider");

  const elements = document.querySelectorAll(".js-slider-single-centered");

  const splideConfig = {
    type: "carousel",
    drag: true,
    perPage: 1,
    arrows: true,
    pagination: false,
  };
}

function cardSlider() {
  console.log("Cards slider");

  const elements = document.querySelectorAll(".js-slider-cards");

  if (!elements) {
    return;
  }

  const splideConfig = {
    type: "loop",
    drag: true,
    arrows: true,
    pagination: false,
    padding: {
      right: "1.5rem",
    },
    // perPage: 3,
    autoWidth: true,
    breakpoints: {
      1280: {
        // autoWidth: true,
        // perPage: 1,
        padding: "1.5rem",
      },
    },
  };

  elements.forEach((el) => {
    let splide = new Splide(el, splideConfig);
    splide.mount();
  });
}

function casesSlider() {
  const container = document.querySelector('[data-slider="cases"]');
  if (!container) {
    return;
  }
  const element = container.querySelector(".js-slider");

  const splideConfig = {
    type: "loop",
    drag: true,
    arrows: false,
    pagination: false,
    padding: "40px",
    gap: "20px",
    autoWidth: false,
    autoHeight: false,
    focusCenter: true,
  };

  const casesSlider = new Splide(element, splideConfig);
  casesSlider.mount();

  const btnPrev = container.querySelector(".js-slider-prev");
  const btnNext = container.querySelector(".js-slider-next");

  //attach events to custom buttons
  btnNext.addEventListener("click", (e) => {
    casesSlider.go("+1");
  });

  btnPrev.addEventListener("click", (e) => {
    casesSlider.go("-1");
  });
}

function knowlegdeBaseSlider() {
  const container = document.querySelector('[data-slider="knowlegdebase"]');

  if (!container) {
    return;
  }

  const element = container.querySelector(".js-slider");

  const splideConfig = {
    type: "loop",
    drag: true,
    arrows: false,
    pagination: false,
    gap: "48px",
    autoWidth: true,
    breakpoints: {
      1280: {
        padding: "40px",
        gap: "20px",
      },
    },
  };

  const knowlegdeSlider = new Splide(element, splideConfig);
  knowlegdeSlider.mount();

  const btnPrev = container.querySelectorAll(".js-slider-prev");
  const btnNext = container.querySelectorAll(".js-slider-next");

  //attach events to custom buttons
  btnNext.forEach((btn) => {
    btn.addEventListener("click", (e) => {
      knowlegdeSlider.go("+1");
    });
  });

  btnPrev.forEach((btn) => {
    btn.addEventListener("click", (e) => {
      knowlegdeSlider.go("-1");
    });
  });
}

export const methodSlider = () => {
  return {
    totalSteps: 5,
    currentStep: null,
    durations: [2, 1.8, 1.7, 2, 1.2],
    isPlaying: false,
    prevStep() {
      if (this.currentStep == 1) {
        return;
      }
      this.currentStep--;
    },
    nextStep() {
      if (this.currentStep == this.totalSteps) {
        this.currentStep = 0;
      }
      this.currentStep++;
    },
    animateStep(step, reverse = false) {
      console.log("MethodSlider animateStep", step);

      this.isPlaying = true;

      const tl = gsap.timeline({
        onComplete: () => {
          this.isPlaying = false;
        },
      });

      tl.to(`.stroke-${step}`, {
        drawSVG: "100%",
        duration: this.durations[step - 1],
        ease: "export.out",
      }).to(
        `#arrow-${step}`,
        {
          motionPath: {
            path: `.stroke-${step}`,
            align: `.stroke-${step}`,
            alignOrigin: [0.5, 0.05],
            autoRotate: -90,
          },
          duration: this.durations[step - 1],
          ease: "export.out",
        },
        0
      );
    },
    init() {
      this.$watch("currentStep", (value, oldValue) => {
        console.log("MethodSlider currentStep", oldValue, value);

        if (oldValue == this.totalSteps) {
          gsap.set(".stroke", {
            drawSVG: "0",
          });
        }

        this.animateStep(value);
      });

      gsap.set(".stroke", {
        drawSVG: "0",
      });

      console.log("MethodSlider init", this.currentStep);
    },
  };
};

export const initSliders = () => {
  infiniteSlider();
  singleCenteredSlider();
  casesSlider();
  knowlegdeBaseSlider();
};

// export methodSlider, initSliders;
