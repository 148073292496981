import jsonp from "jsonp";

export const newsletterSignup = ($el) => {
  return {
    email: null,
    error: false,
    success: false,
    working: false,

    submitForm() {
      this.working = true;

      const url = this.$refs.signupForm.action.concat(`&EMAIL=${this.email}`);

      jsonp(
        url,
        {
          param: "c",
        },
        (error, data) => {
          console.log(error, data);

          if (error) {
            this.error = data.msg;
            this.succes = false;
          } else if (data.result !== "success") {
            this.error = data.msg;
            this.succes = false;
          } else {
            this.error = false;
            this.success = data.msg;
          }

          this.working = false;
        }
      );

      //   fetch(this.$refs.signupForm.action, {
      //     method: "POST",
      //     headers: {
      //       Accept: "application/json",
      //       "Content-Type": "application/json",
      //     },
      //     mode: "no-cors",
      //     body: new FormData(this.$refs.signupForm),
      //   })
      //     .then((json) => {
      //       console.log(json);
      //       if (json.error) {
      //         this.error = json.msg;
      //         this.succes = false;
      //       } else {
      //         this.error = false;
      //         this.succes = "Yeah! You're signed up!";
      //       }
      //     })
      //     .finally(() => {
      //       this.working = false;
      //     });
    },
  };
};
