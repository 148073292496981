/* CSS */
import "../css/site.css";

/* JS */
// Alpine
import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";
import intersect from "@alpinejs/intersect";

// Other libraries
import mojs from "@mojs/core";
import lazysizes from "lazysizes";
import "@justinribeiro/lite-youtube";

// Components
import { header } from "./components/header";
import { newsletterSignup } from "./components/footer";
import {
  resetTextEffects,
  initAnimations,
} from "./components/contentAnimations";
import { initSliders, methodSlider } from "./components/sliders";

const app = () => {
  return {
    meetModalOpen: false,
    caseCoverInView: window.caseCoverInView ?? false,
  };
};

// Main Functions
function init() {
  console.log("Hello world");

  window.mixit = {
    app,
    header,
    newsletterSignup,
    methodSlider,
  };

  initComponents();

  /**
   * Alpine JS
   * Best to put it last so that all other potential JS is available
   * when components start getting initialized.
   */
  window.Alpine = Alpine;
  Alpine.plugin(collapse);
  Alpine.plugin(intersect);
  Alpine.start();
}

function initComponents() {
  initAnimations();
  initSliders();
  // const rellax = new Rellax(".rellax");
}

// Check for resize event
window.addEventListener("resize", () => {
  resetTextEffects();
});

// document.querySelectorAll(".js-burst").forEach(($element) => {
//   const itemDim = $element.getBoundingClientRect(),
//     itemSize = {
//       x: itemDim.right - itemDim.left,
//       y: itemDim.bottom - itemDim.top,
//     };

//   // create shape
//   const burst = new mojs.Burst({
//     left: 0,
//     top: 0,
//     radius: { 0: $element.getAttribute("data-radius") || 30 },
//     count: 10,
//     children: {
//       shape: "line",
//       //       fill: "white",
//       //       radius: { 5: 0 },
//       //       scale: 1,
//       //       stroke: $element.getAttribute("data-color"),
//       //       strokeWidth: 2,
//       //       duration: 1500,
//       //       easing: mojs.easing.bezier(0.1, 1, 0.3, 1),
//       radius: { 5: 0 },
//       scale: 1,
//       fill: "white",
//       points: 8,
//       stroke: $element.getAttribute("data-color") || "white",
//       strokeWidth: 2,
//       // strokeDasharray: "100%",
//       // strokeDashoffset: { "-100%": "100%" },
//       duration: 1450,
//       // delay: 100,
//       easing: mojs.easing.bezier(0.1, 1, 0.3, 1),
//       isShowEnd: false,
//     },
//   });

//   // Add the mouseenter listener to play the burst.
//   $element.addEventListener("mouseenter", function (e) {
//     const box = e.target.getBoundingClientRect();

//     burst.tune({
//       x: box.left + window.pageXOffset + box.width / 2,
//       y: box.top + window.pageYOffset + box.height / 2,
//     });

//     burst.play();
//   });
// });

document.querySelectorAll(".js-burst").forEach(($element) => {
  // const burst = new mojs.Burst({
  //   left: 0,
  //   top: 0,
  //   radius: { 0: $element.getAttribute("data-radius") || 30 },
  //   count: 10,
  //   children: {
  //     shape: "line",
  //     fill: "white",
  //     radius: { 5: 0 },
  //     scale: 1,
  //     stroke: $element.getAttribute("data-color"),
  //     strokeWidth: 2,
  //     duration: 1500,
  //     easing: mojs.easing.bezier(0.1, 1, 0.3, 1),
  //   },
  // });

  const lines = new mojs.Burst({
    left: 0,
    top: 0,
    radius: { 0: $element.getAttribute("data-radius") || 30 },
    angle: 0,
    count: 10,
    children: {
      shape: "line",
      radius: { 5: 0 },
      scale: 1,
      fill: "white",
      stroke: $element.getAttribute("data-color") || "white",
      strokeWidth: 2,
      // strokeDasharray: "100%",
      // strokeDashoffset: { "-100%": "100%" },
      duration: 700,
      easing: mojs.easing.bezier(0.1, 1, 0.3, 1),
    },
  });

  $element.addEventListener("mouseenter", (e) => {
    const box = e.target.getBoundingClientRect();

    lines.tune({
      x: box.left + window.pageXOffset + box.width / 2,
      y: box.top + window.pageYOffset + box.height / 2,
    });

    // burst.tune({
    //   x: box.left + window.pageXOffset + box.width / 2,
    //   y: box.top + window.pageYOffset + box.height / 2,
    // });

    lines.play();
  });
});

window.addEventListener("DOMContentLoaded", init);

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}
